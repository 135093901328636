<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="page-title mb-8">{{ $t("Educational History") }}</h2></v-col
      >
      <v-col :class="currentAppLocale == 'ar' ? 'text-left' : 'text-right'">
        <!-- <v-btn @click="print" class="main-color-back" v-if="is_active">
          <v-icon class="mr-3" :title="$t('Print')">print</v-icon>
          {{ $t("Report Card") }}</v-btn
        >

        <v-btn @click="printDetailes" v-if="is_active" class="main-color-back">
          <v-icon class="mr-3" :title="$t('Print Details')">print </v-icon>
          {{ $t("Details") }}
        </v-btn> -->
      </v-col>
    </v-row>

    <div v-for="(year, idx) in quartersDetails" :key="idx">
      <h2 class="mt-6 main-color">
        {{ year.academic_period_name }} - {{ year.grade_name }}
      </h2>
      <v-expansion-panels v-model="panels[idx].panel">
        <v-expansion-panel
          v-for="(item, index) in year.quarters"
          :key="index"
          @click="fetchSubjects(idx, year.academic_grade_id, item.id)"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <p class="mb-0">{{ $t("Quarters") }} : {{ item.name }}</p>

              <p class="mb-0" v-if="is_active && panels[idx].panel == index">
                {{ $t("Total Mark") }}: {{ total_mark }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mb-3">
              <v-col class="text-center">
                <v-btn
                  @click="printCard(year.academic_grade_id, item.id)"
                  class="main-color-back mb-0"
                  v-if="is_active && panels[idx].panel == index"
                >
                  <v-icon :title="$t('Print')">print</v-icon>
                  {{ $t("Report Card") }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-btn
              @click="printCard(year.academic_grade_id, item.id)"
              class="main-color-back mb-0"
              v-if="is_active && panels[idx].panel == index"
            >
              <v-icon :title="$t('Print')">print</v-icon>
              {{ $t("Report Card") }}
            </v-btn> -->
            <v-simple-table
              class="elevation-1 level1"
              v-if="
                activeQuarter == item.id &&
                quarterData &&
                quarterData.length > 0
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("Subject") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Mark") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Details") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in quarterData" :key="index">
                    <td>{{ item.subject_name }}</td>
                    <td>
                      <p
                        class="passed mb-0"
                        v-if="
                          item &&
                          item.passing_mark &&
                          Number(item.student_mark) >= Number(item.passing_mark)
                        "
                      >
                        {{ item.mark_display }}
                      </p>
                      <p
                        class="faild mb-0"
                        v-if="
                          item &&
                          item.passing_mark &&
                          Number(item.student_mark) < Number(item.passing_mark)
                        "
                      >
                        {{ item.mark_display }}
                      </p>
                      <p v-if="!item.passing_mark" class="unAssignedMark mb-0">
                        {{ item.mark_display }}
                      </p>
                    </td>

                    <td>
                      <span
                        v-for="(q, index) in item.qualifiers"
                        :key="index"
                        class="student_qualifier"
                      >
                        {{ q.qualifier_title }} {{ q.student_mark }} /
                        {{ q.qualifier_total }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p v-else>{{ msg }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "studentView",
  data() {
    return {
      msg: this.$i18n.t("Loading Data ..."),
      panels: [],
      quartersDetails: [],
      quarterData: [],
      activeQuarter: 0,
      academic_grade_id: "",
      is_active: false,
      total_mark: "",
    };
  },

  methods: {
    getQuartersDetails() {
      axios
        .get(this.getApiUrl + "/educational-history/student/quarters", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quartersDetails = response.data.data;

          this.quartersDetails.forEach((item, index) => {
            let obj = { panel: "" };

            this.panels.push(obj);
          });

          this.panels[0].panel = 0;
          this.fetchSubjects(
            0,
            this.quartersDetails[0].academic_grade_id,
            this.quartersDetails[0].quarters[0].id
          );
        });
    },

    fetchSubjects(yearIndx, academic_grade_id, quarter_id) {
      this.is_active = false;
      this.msg = this.$i18n.t("Loading Data ...");
      setTimeout(() => {
        if (
          this.panels[yearIndx].panel !== "" &&
          this.panels[yearIndx].panel !== undefined
        ) {
          this.activeQuarter = "";
          this.quarterData = [];
          this.panels.forEach((panel, index) => {
            if (index != yearIndx) {
              panel.panel = "";
            }
          });
          axios
            .get(
              this.getApiUrl +
                "/educational-history/student/quarters/subjects/" +
                quarter_id +
                "?academic_grade_id=" +
                academic_grade_id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.is_active = response.data.data.is_active;
              this.total_mark = response.data.data.student_mark;
              if (response.data.data.is_active == true) {
                // console.log(response.data.data.is_active);
                this.activeQuarter = response.data.data.quarter_id;

                this.quarterData = response.data.data.subjects;
                // this.quarterData.is_active = response.data.data.is_active;

                if (this.quarterData && this.quarterData.length == 0) {
                  this.msg = this.$i18n.t("No data available");
                }
              } else {
                this.msg = this.$i18n.t("No data available");
              }
            });
        }
      }, 200);
    },
    printCard(academic_grade_id, quarter_id) {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/student/quarters/subjects/printCard?&academic_grade_id=" +
            academic_grade_id +
            "&quarter_id=" +
            quarter_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },

    print() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/student/quarters/subjects/print",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
    printDetailes() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/student/quarters/subjects/printDetailes",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
  },
  mounted() {
    this.getQuartersDetails();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.passed {
  color: green;
}
.faild {
  color: red;
}
.unAssignedMark {
}

.student_qualifier {
  display: inline-block;
  margin: 0 15px;
}
.main-color-back {
  background-color: #7297ff !important;
  border-color: #7297ff !important;
  color: #f7f7ff !important;
  margin-right: 20px !important;
}
.text-left {
  text-align: left !important;
}
</style>
